(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/pool-betting/best-bet-rows/assets/javascripts/single-rows-presentation.js') >= 0) return;  svs.modules.push('/components/pool-betting/best-bet-rows/assets/javascripts/single-rows-presentation.js');
"use strict";


const SingleRowsPresentation = _ref => {
  let {
    children,
    className
  } = _ref;
  return React.createElement("div", {
    className: ['pb_best_bet_rows'].concat(className || []).join(' ')
  }, children);
};
setGlobal('svs.components.poolBetting.bestBetRows.SingleRowsPresentation', SingleRowsPresentation);

 })(window);