(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/pool-betting/best-bet-rows/assets/javascripts/best-bet-rows.js') >= 0) return;  svs.modules.push('/components/pool-betting/best-bet-rows/assets/javascripts/best-bet-rows.js');
"use strict";

function _classPrivateFieldInitSpec(e, t, a) { _checkPrivateRedeclaration(e, t), t.set(e, a); }
function _checkPrivateRedeclaration(e, t) { if (t.has(e)) throw new TypeError("Cannot initialize the same private elements twice on an object"); }
function _classPrivateFieldGet(s, a) { return s.get(_assertClassBrand(s, a)); }
function _classPrivateFieldSet(s, a, r) { return s.set(_assertClassBrand(s, a), r), r; }
function _assertClassBrand(e, t, n) { if ("function" == typeof e ? e === t : e.has(t)) return arguments.length < 3 ? t : n; throw new TypeError("Private element is not present on this object"); }

const {
  getLogger
} = svs.core.log;
const {
  getDefinitionByProduct
} = svs.components.tipsen.engine.utils;
const {
  helpers
} = svs.components.tipsen.store;
const {
  utils
} = svs.components.tipsen.expandedSystems;
const {
  composeCacheKey
} = svs.components.poolBetting.bestBetRows.utils;
const logger = getLogger('pool-betting:best-bet-rows');

var _srows = new WeakMap();
var _drawNumber = new WeakMap();
var _maxCapRowCount = new WeakMap();
var _totalRowCount = new WeakMap();
var _calculateBestBetRows = new WeakMap();
var _resultOutcomes = new WeakMap();
class BestBetRows {
  constructor(boardData) {
    let {
      drawNumber,
      maxCapRowCount = 60,
      productId,
      resultOutcomes = [],
      systemNum,
      systemType
    } = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
    _classPrivateFieldInitSpec(this, _srows, []);
    _classPrivateFieldInitSpec(this, _drawNumber, 0);
    _classPrivateFieldInitSpec(this, _maxCapRowCount, 0);
    _classPrivateFieldInitSpec(this, _totalRowCount, 0);
    _classPrivateFieldInitSpec(this, _calculateBestBetRows, void 0);
    _classPrivateFieldInitSpec(this, _resultOutcomes, []);
    logger.info('BestBetRows constructor draw', drawNumber);
    const eventTypeId = productId ? getDefinitionByProduct(productId).outcomes.eventTypeId : 1;
    _classPrivateFieldSet(_maxCapRowCount, this, maxCapRowCount);
    const startTime = new Date();
    _classPrivateFieldSet(_srows, this, helpers.getExpandedRows(boardData, systemType, systemNum, {
      eventTypeId
    }));
    _classPrivateFieldSet(_drawNumber, this, drawNumber);
    _classPrivateFieldSet(_totalRowCount, this, _classPrivateFieldGet(_srows, this).length);
    _classPrivateFieldSet(_resultOutcomes, this, resultOutcomes);
    const systemNumString = systemNum ? "-".concat(systemNum) : '';
    logger.info("Expanded srows for system ".concat(systemType).concat(systemNumString, " ").concat(this.totalRowCount, " rows in ").concat(Date.now() - startTime, "ms"));
    _classPrivateFieldSet(_calculateBestBetRows, this, utils.calculateBestBetRows(_classPrivateFieldGet(_srows, this), undefined, undefined, {
      productId
    }));
    this.calculate = this.calculate.bind(this);
  }
  set resultOutcomes(outcomes) {
    _classPrivateFieldSet(_resultOutcomes, this, outcomes);
  }
  get resultOutcomes() {
    return _classPrivateFieldGet(_resultOutcomes, this);
  }
  get srows() {
    return _classPrivateFieldGet(_srows, this);
  }
  get totalRowCount() {
    return _classPrivateFieldGet(_totalRowCount, this);
  }

  calculate(winDivisions) {
    var _bestBetRows$find;
    const then = new Date();
    const cacheKey = composeCacheKey(_classPrivateFieldGet(_resultOutcomes, this));
    const correctRowGroups = _classPrivateFieldGet(_calculateBestBetRows, this).call(this, _classPrivateFieldGet(_resultOutcomes, this));
    const lowestWinDivision = winDivisions.slice().pop();
    const bestBetRows = [];
    const winDivisionsRowCount = [];
    let currentCount = 0;
    for (const [index, group] of correctRowGroups.entries()) {
      const winDivision = index + 1;
      const numberOfRowsInGroup = group.length;
      let slice;
      winDivisionsRowCount.push(numberOfRowsInGroup);

      if (lowestWinDivision && lowestWinDivision >= winDivision) {
        bestBetRows.push(group.slice(0, slice));
        currentCount += numberOfRowsInGroup;
        continue;
      } else if (numberOfRowsInGroup + currentCount > _classPrivateFieldGet(_maxCapRowCount, this)) {
        slice = Math.max(_classPrivateFieldGet(_maxCapRowCount, this) - currentCount, 0);
      }
      bestBetRows.push(group.slice(0, slice));
      currentCount += numberOfRowsInGroup;
    }
    logger.debug('#resultOutcomes', _classPrivateFieldGet(_resultOutcomes, this));
    logger.debug('winDivisionsRowCount', winDivisionsRowCount);
    logger.debug('calculated best bet rows', bestBetRows);
    logger.info("Calculated (".concat(this.totalRowCount, ") best bet rows for cache ").concat(cacheKey, " in ").concat(Date.now() - then, "ms (draw ").concat(_classPrivateFieldGet(_drawNumber, this), ")"));
    return Object.freeze({
      bestBetRows: Object.freeze(bestBetRows),
      winDivisionsRowCount: Object.freeze(winDivisionsRowCount),
      bestBetRow: Object.freeze((_bestBetRows$find = bestBetRows.find(winDiv => winDiv.length > 0)) === null || _bestBetRows$find === void 0 ? void 0 : _bestBetRows$find[0])
    });
  }
}
setGlobal('svs.components.poolBetting.bestBetRows.BestBetRows', BestBetRows);

 })(window);