(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/pool-betting/best-bet-rows/assets/javascripts/hooks/use-draw-result-outcomes.js') >= 0) return;  svs.modules.push('/components/pool-betting/best-bet-rows/assets/javascripts/hooks/use-draw-result-outcomes.js');
"use strict";


const {
  useMemo
} = React;
const {
  useObservedDrawResult
} = svs.components.tipsen.hooksWager;
const defaultReturn = [[], [], undefined];
const useDrawResultOutcomes = _ref => {
  let {
    wagerId
  } = _ref;
  const {
    current
  } = useObservedDrawResult({
    wagerId
  });
  const [drawResults, winDivisions, multiForecast] = current;
  const multiForecastEventNumberIndex = useMemo(() => {
    if (multiForecast) {
      return multiForecast.eventNumberIndex;
    }
    return undefined;
  }, [multiForecast]);
  return useMemo(() => {
    if (!winDivisions) {
      return defaultReturn;
    }
    const newDrawResultOutcomes = drawResults.map(_ref2 => {
      let {
        outcome
      } = _ref2;
      return outcome.map(outcome => outcome);
    });
    const newWinDivisions = winDivisions.map(_ref3 => {
      let {
        winDiv
      } = _ref3;
      return winDiv;
    }).sort((a, b) => a - b);
    return [newDrawResultOutcomes, newWinDivisions, multiForecastEventNumberIndex];
  }, [drawResults, winDivisions, multiForecastEventNumberIndex]);
};
setGlobal('svs.components.poolBetting.bestBetRows.hooks.useDrawResultOutcomes', useDrawResultOutcomes);

 })(window);