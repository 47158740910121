(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/pool-betting/best-bet-rows/assets/javascripts/utils/compose-cache-key.js') >= 0) return;  svs.modules.push('/components/pool-betting/best-bet-rows/assets/javascripts/utils/compose-cache-key.js');
"use strict";


const composeCacheKey = resultOutcomes => {
  const cacheKeyItems = resultOutcomes.map(resultOutcome => {
    if (resultOutcome.length === 0) {
      return '?';
    }
    return resultOutcome.join('-');
  });
  return cacheKeyItems.join();
};
setGlobal('svs.components.poolBetting.bestBetRows.utils.composeCacheKey', composeCacheKey);

 })(window);