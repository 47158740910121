(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/pool-betting/best-bet-rows/assets/javascripts/utils/compose-modified-result-outcomes.js') >= 0) return;  svs.modules.push('/components/pool-betting/best-bet-rows/assets/javascripts/utils/compose-modified-result-outcomes.js');
"use strict";


const MASKED_VALUE = '?';

const composeModifiedResultOutcomes = _ref => {
  let {
    drawResultOutcomes,
    maskedOutcomes,
    alternativeOutcomes
  } = _ref;
  if (drawResultOutcomes.length < 1 || !maskedOutcomes && !alternativeOutcomes) {
    return drawResultOutcomes;
  }
  const modifiedDrawResultOutcomes = [...drawResultOutcomes];
  if (Array.isArray(alternativeOutcomes)) {
    alternativeOutcomes.forEach(alternativeOutcome => {
      var _modifiedDrawResultOu;
      const {
        eventIndex,
        outcomeValue
      } = alternativeOutcome;
      if (typeof eventIndex !== 'number' || eventIndex < 0 || eventIndex >= modifiedDrawResultOutcomes.length) {
        throw new Error("Invalid alternativeOutcome eventIndex ".concat(eventIndex, " out of bounds"), eventIndex);
      }
      const drawResultOutcome = (_modifiedDrawResultOu = modifiedDrawResultOutcomes[eventIndex][0]) !== null && _modifiedDrawResultOu !== void 0 ? _modifiedDrawResultOu : [];
      if (outcomeValue.length !== drawResultOutcome.length || !outcomeValue.every(outcomeValue => typeof outcomeValue === 'number')) {
        throw new Error("Invalid alternativeOutcome outcomeValue ".concat(outcomeValue));
      }
      modifiedDrawResultOutcomes[eventIndex] = [outcomeValue];
    });
  }

  if (Array.isArray(maskedOutcomes)) {
    maskedOutcomes.forEach((maskedOutcomeIndex, index) => {
      if (typeof maskedOutcomeIndex !== 'number' || maskedOutcomeIndex < 0) {
        throw new Error("Invalid maskedOutcomeIndex ".concat(maskedOutcomeIndex, " out of bounds"), maskedOutcomeIndex);
      }
      if ((modifiedDrawResultOutcomes === null || modifiedDrawResultOutcomes === void 0 ? void 0 : modifiedDrawResultOutcomes.length) < 1) {
        return;
      }
      modifiedDrawResultOutcomes[maskedOutcomeIndex] = (drawResultOutcomes === null || drawResultOutcomes === void 0 ? void 0 : drawResultOutcomes[index].length) === 2 ? [[MASKED_VALUE], [MASKED_VALUE]] : [[MASKED_VALUE]];
    });
  }
  return modifiedDrawResultOutcomes;
};
setGlobal('svs.components.poolBetting.bestBetRows.utils.composeModifiedResultOutcomes', composeModifiedResultOutcomes);

 })(window);