(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/pool-betting/best-bet-rows/assets/javascripts/best-bet-rows-grid.js') >= 0) return;  svs.modules.push('/components/pool-betting/best-bet-rows/assets/javascripts/best-bet-rows-grid.js');
"use strict";


const {
  useMemo,
  useRef
} = React;
const {
  useSelector
} = ReactRedux;
const {
  DraggableContainer
} = svs.components.lbUi;
const {
  selectMaskedOutcomes
} = svs.components.tipsen.store.reducers.resultModifier.selectors;
const {
  hooks: {
    useBestBetRows
  },
  SingleRowsPresentation,
  SingleRow
} = svs.components.poolBetting.bestBetRows;
const {
  getDefinitionByProduct
} = svs.components.tipsen.engine.utils;
const {
  getLogger
} = svs.core.log;
const logger = getLogger('pool-betting:best-bet-rows');
const BestBetRowsGrid = _ref => {
  let {
    wagerId
  } = _ref;
  const _maskedOutcomes = useSelector(state => selectMaskedOutcomes(state, wagerId));
  const maskedOutcomes = useMemo(() => _maskedOutcomes || [], [_maskedOutcomes]);
  const {
    getBestBetRows
  } = useBestBetRows();
  const {
    bestBetRows,
    resultOutcomes,
    winDivisions,
    productId
  } = getBestBetRows();
  const eventTypeId = productId ? getDefinitionByProduct(productId).outcomes.eventTypeId : 0;
  const rowNumber = useRef(0);
  const bestBetRowComponents = useMemo(() => {
    rowNumber.current = 0;
    if (!bestBetRows.length) {
      return null;
    }
    const rows = [];
    const numMatches = resultOutcomes.length;
    for (const [winDivisionKey, winDivisionValue] of bestBetRows.entries()) {
      if (!winDivisionValue.length) {
        continue;
      }
      rows.push(...winDivisionValue.map((row, index) => {
        const totalCorrects = resultOutcomes.length ? numMatches - winDivisionKey : '-';
        const winDiv = winDivisionKey + 1;
        const maskedResultOutcomes = resultOutcomes.map((drawResultOutcome, index) => {
          if (maskedOutcomes.includes(index)) {
            return [];
          }
          return drawResultOutcome;
        });
        if (winDivisionValue.length >= 60) {
          rowNumber.current = index + 1;
        } else {
          rowNumber.current = rowNumber.current + 1;
        }
        return React.createElement(SingleRow, {
          className: winDivisions.includes(winDiv) ? 'pb_best_bet_rows__row--is-in-pricegroup' : '',
          eventTypeId: eventTypeId
          ,
          key: row.join('') + index,
          resultOutcomes: maskedResultOutcomes,
          rowNumber: rowNumber.current,
          rowOutcomes: row,
          topic: totalCorrects
        });
      }));
    }
    return React.createElement(DraggableContainer, null, rows);
  }, [bestBetRows, resultOutcomes, winDivisions, eventTypeId, maskedOutcomes]);
  return React.createElement(SingleRowsPresentation, null, bestBetRowComponents);
};
setGlobal('svs.components.poolBetting.bestBetRows.BestBetRowsGrid', BestBetRowsGrid);

 })(window);