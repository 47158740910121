(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/pool-betting/best-bet-rows/assets/javascripts/utils/get-correct-event-count.js') >= 0) return;  svs.modules.push('/components/pool-betting/best-bet-rows/assets/javascripts/utils/get-correct-event-count.js');
"use strict";


const getCorrectEventCount = function () {
  let bestBetRows = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];
  if (!bestBetRows.length) {
    return 0;
  }
  let firstNonEmptyIndex = 0;
  for (let i = 0; i < bestBetRows.length; i++) {
    if (bestBetRows[i].length > 0) {
      firstNonEmptyIndex = i;
      break;
    }
  }
  return bestBetRows.length - 1 - firstNonEmptyIndex;
};
setGlobal('svs.components.poolBetting.bestBetRows.utils.getCorrectEventCount', getCorrectEventCount);

 })(window);