(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/pool-betting/best-bet-rows/assets/javascripts/single-row.js') >= 0) return;  svs.modules.push('/components/pool-betting/best-bet-rows/assets/javascripts/single-row.js');
"use strict";


const {
  Outcome
} = svs.components.lbUi.outcome;
const {
  signIndexToOutcome
} = svs.components.sport.tipsenShared;
const {
  useBranding
} = svs.components.tipsen.hooks;
const {
  hasValidOutcomePair
} = svs.components.poolBetting.utils;
const composeOutcomeLabel = _ref => {
  let {
    outcome = [],
    eventTypeId
  } = _ref;
  if (!Array.isArray(outcome)) {
    return '';
  }
  if (outcome.length === 1) {
    return signIndexToOutcome[outcome[0]];
  }
  return "".concat(signIndexToOutcome[eventTypeId][outcome[0]], " - ").concat(signIndexToOutcome[eventTypeId][outcome[1]]);
};
const SingleRow = React.memo(_ref2 => {
  let {
    className,
    eventTypeId,
    resultOutcomes,
    rowNumber,
    rowOutcomes,
    topic
  } = _ref2;
  const [productBranding] = useBranding();
  return React.createElement("div", {
    className: ['pb_best_bet_rows__row'].concat(className || []).join(' ')
  }, rowNumber && React.createElement("div", {
    className: "pb_best_bet_rows__row_header"
  }, rowNumber), React.createElement("div", {
    className: "pb_best_bet_rows__row_topic"
  }, topic), React.createElement("div", {
    className: "pb_best_bet_rows__row_outcomes"
  }, rowOutcomes.map((outcome, index) =>
  React.createElement("div", {
    className: "pb_best_bet_rows__row_outcome_container",
    key: "pb_best_bet_rows__row_outcome_container_".concat(outcome).concat(index)
  }, React.createElement(Outcome, {
    branding: productBranding,
    className: "pb_best_bet_rows__row_outcome",
    isSelected: hasValidOutcomePair({
      outcomePairs: [outcome.join('-')],
      resultOutcome: resultOutcomes === null || resultOutcomes === void 0 ? void 0 : resultOutcomes[index],
      maxOutcomeIndex: 10
    })
    ,
    key: "".concat(outcome).concat(index),
    sign: composeOutcomeLabel({
      outcome,
      eventTypeId
    })
  })))));
}, (prevProps, nextProps) => prevProps.rowOutcomes.join('') === nextProps.rowOutcomes.join('') && prevProps.resultOutcomes.join('') === nextProps.resultOutcomes.join('') && prevProps.topic === nextProps.topic && prevProps.className === nextProps.className);
setGlobal('svs.components.poolBetting.bestBetRows.SingleRow', SingleRow);

 })(window);