(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/pool-betting/best-bet-rows/assets/javascripts/hooks/use-is-best-bet-rows-supported.js') >= 0) return;  svs.modules.push('/components/pool-betting/best-bet-rows/assets/javascripts/hooks/use-is-best-bet-rows-supported.js');
"use strict";


const {
  useLocation
} = ReactRouterDOM;
const {
  useGetPlayerWagerQuery
} = svs.components.tipsen.store.tipsenApi;
const {
  wagerSystemTypes
} = svs.components.tipsen.engine.constants;
const supportedBestBetRowsSystems = [wagerSystemTypes.USYS, wagerSystemTypes.RSYS, wagerSystemTypes.REDUCERA_FRITT, wagerSystemTypes.OWNROWS];

const useIsBestBetRowsSupported = function (wagerId) {
  var _boardData$boards;
  let {
    requireQueryString = false
  } = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  const wager = useGetPlayerWagerQuery({
    wagerId
  }, {
    skip: !wagerId
  });
  const {
    search
  } = useLocation();
  const qs = new URLSearchParams(search);
  if (!wager.data || !wager.isSuccess) {
    return false;
  }
  if (qs.has('bbr')) {
    return true;
  }
  if (requireQueryString) {
    return false;
  }
  const {
    betProperties,
    boardData,
    systemType
  } = wager.data.productBet.gameBoard.boards;
  if (!systemType && !betProperties) {
    return false;
  }
  const currentSystems = [].concat(systemType, betProperties || []);
  const shouldShowBestBetRow = supportedBestBetRowsSystems.some(systemType => currentSystems.includes(systemType));
  const shouldShowSimpleRows = !shouldShowBestBetRow && systemType === wagerSystemTypes.SROW && ((_boardData$boards = boardData.boards) === null || _boardData$boards === void 0 ? void 0 : _boardData$boards.length) > 1;
  return shouldShowBestBetRow || shouldShowSimpleRows;
};
setGlobal('svs.components.poolBetting.bestBetRows.hooks.useIsBestBetRowsSupported', useIsBestBetRowsSupported);

 })(window);